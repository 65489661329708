import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Row, Col } from "antd";
import Carousel from "nuka-carousel";
import { motion } from "framer-motion";
import { isMobileOnly } from "react-device-detect";
import ProductSlide from "./product-slide";
import ArrowLeft from "../../components/CarouselCustomControls/ArrowLeft";
import ArrowRight from "../../components/CarouselCustomControls/ArrowRight";
import useWindowSize from "../../hooks/use-window-size";
import Styles from "./styles";
import useStartMotion from "../../hooks/use-start-motion";

const variants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
  },
};

const ProductLines = (props) => {
  const { sectionData, productSlides, animations } = props;
  const [numberOfSlides, setNumberOfSlides] = useState(3);
  const [cellSpacing, setCellSpacing] = useState(10);
  const size = useWindowSize();
  const { ref, controls } = useStartMotion();

  useEffect(() => {
    const { width } = size;
    const isMobile = width <= 576;
    const isTablet = width > 576 && width < 992;
    const isSreenLG = width > 992 && width < 1200;
    const screenXL = width > 1200 && width <= 1700;
    const screenXXL = width > 1700;

    const slides = () => {
      if (isMobile) return 1.3;
      if (isTablet) return 2.5;
      if (isSreenLG) return 2.5;
      if (screenXL) return 3.5;
      if (screenXXL) return 5;
      return 2;
    };
    const spacing = () => {
      if (isMobile) return 0;
      if (isTablet) return 70;
      if (screenXXL) return 80;
      return 80;
    };

    setNumberOfSlides(slides);
    setCellSpacing(spacing);
  }, [size]);

  return (
    <motion.section
      className="products"
      ref={ref}
      animate={controls}
      variants={variants}
      initial={isMobileOnly || !animations ? "visible" : "hidden"}
      transition={{ duration: 0.8 }}
    >
      <Row className="products__text">
        <Col span={24}>
          <div className="extra-large-heading">
            {sectionData.title}
            <span className="blue_dot">.</span>{" "}
          </div>
          <p>{sectionData.subText}</p>
        </Col>
      </Row>

      <div
        className="products__carousel-wrapper"
        style={{ maxWidth: 2000, overflow: "hidden" }}
      >
        <Carousel
          slidesToShow={numberOfSlides}
          cellSpacing={cellSpacing}
          cellAlign="center"
          enableKeyboardControls
          dragging
          slideIndex={8}
          wrapAround
          className="products__carousel"
          renderCenterLeftControls={({ previousSlide }) => (
            <ArrowLeft onClick={previousSlide} />
          )}
          renderCenterRightControls={({ nextSlide }) => (
            <ArrowRight onClick={nextSlide} />
          )}
          renderBottomCenterControls={() => null}
        >
          {productSlides.map((slide, idx) => (
            <ProductSlide
              key={idx.toString()}
              img={slide.img}
              title={slide.title}
              link={slide.link}
            />
          ))}
        </Carousel>
      </div>

      <Styles />
    </motion.section>
  );
};

ProductLines.propTypes = {
  sectionData: PropTypes.objectOf(PropTypes.any),
  productSlides: PropTypes.arrayOf(PropTypes.any),
  animations: PropTypes.bool,
};
ProductLines.defaultProps = {
  sectionData: {},
  productSlides: [],
  animations: true,
};

export default ProductLines;
