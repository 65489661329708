import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import Image from "../../components/ImageQuerys/ProductImages";

const ProductSlide = (props) => {
  const { title, img, link } = props;

  return (
    <div className="product__slide">
      <Link to={link}>
        <Image
          className="product__slide--image"
          imageName={img}
          alt="image slide"
        />
        <div className="product__slide--title">
          <h3>{title}</h3>
        </div>
      </Link>
    </div>
  );
};

ProductSlide.propTypes = {
  title: PropTypes.string,
  img: PropTypes.string,
  link: PropTypes.string,
};
ProductSlide.defaultProps = {
  img: "",
  link: "",
  title: "",
};

export default ProductSlide;
