import React from "react";
import { viewports } from "../../style-vars";

const Styles = () => (
  <style jsx global>{`
    .products {
      position: relative;
      width: 100%;
      margin: 80px 0 0;
      max-width: 1980px;
    }
    //.products .products__carousel,
    //.products .slider-frame,
    //.products .slider-list {
    //  max-width: 1980px !important;
    //}

    .products__text {
      max-width: 700px;
      margin: 0 auto;
      text-align: center;
    }

    .products__text p {
      color: #748093;
      font-size: 16px;
      line-height: 1.7;
      margin-top: 24px;
      //margin-bottom: 80px !important;
      padding: 0 30px;
    }

    .products__carousel-wrapper {
      max-width: 2000px;
      min-height: 410px;
      overflow: hidden;
      display: flex;
      align-items: center;
    }

    .products__carousel .slider-frame {
      overflow: visible !important;
      padding-left: 3% !important;
    }
    @media ${viewports.mdOrBigger} {
      .products__carousel .slider-frame {
        padding-left: 0 !important;
      }
    }
    @media ${viewports.lgOrBigger} {
      .products__carousel .slider-frame {
        padding-left: 1% !important;
      }
    }
    @media ${viewports.xlOrBigger} {
      .products__carousel .slider-frame {
        padding-left: 0 !important;
      }
    }

    .products__cta {
      justify-content: center;
    }

    .products .cta-secondary {
      margin: 42px auto 82px auto;
    }

    .products .slick-slider button.slick-arrow {
      z-index: 1;
      background: red;
    }
    .products .slick-slider .arrow-left {
      position: absolute;
      z-index: 1;
      left: 20px;
      top: 40%;
    }
    .products .slick-slider .arrow-right {
      position: absolute;
      z-index: 1;
      top: 40%;
      right: 20px;
    }

    .product__slide {
      position: relative;
      //max-width: 267px;
      //max-height: 267px;
      width: 267px;
      height: 267px;
      background: #f3f4f6;
      border-radius: 50%;
      padding: 35px;
      margin: 0 auto;
    }

    .product__slide--title {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .product__slide--title h3 {
      font-size: 16px;
      font-weight: 700;
      max-width: 95px;
      text-align: center;
      max-height: 25px;
    }

    .arrow {
      cursor: pointer;
    }
    .slider-control-centerleft {
      z-index: 2;
    }
    .slider-control-centerright {
      z-index: 2;
    }

    .products li.slider-slide {
      max-width: 267px;
      max-height: 267px;
    }

    @media ${viewports.smOrBigger} {
      .products__text p {
        padding: 0 100px;
      }
    }

    @media ${viewports.mdOrBigger} {
      .products__carousel .slider-frame .slide-current {
        transform: scale(1.2) !important;
      }

      .products__text p {
        margin-bottom: 40px;
      }
    }

    @media ${viewports.lgOrBigger} {
      .product__slide {
        width: 330px;
        height: 330px;
      }

      .products .ant-carousel .slick-slider {
        height: 400px !important;
        overflow: hidden !important;
        padding: 15px 0;
      }
      .products .ant-carousel .slick-list {
        overflow: visible !important;
        padding-top: 20px !important;
        padding-bottom: 20px !important;
      }
      .product__slide--title h4 {
        font-size: 18px;
        max-width: fit-content;
        width: 60%;
        max-height: 30px;
      }
      .products div.slick-center {
        transform: scale(1.2) !important;
        transition: 0.5s ease-in-out;
      }
      .products__text p {
        margin-bottom: 80px;
      }
    }

    @media ${viewports.maxContentWidthOrBigger} {
      .products {
        margin: 60px auto;
      }

      .products .slick-list {
        //padding-left: 23% !important;
      }

      .products li.slider-slide {
        width: 330px;
        height: 330px;
      }
    }
  `}</style>
);

export default Styles;
